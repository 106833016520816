export class StringUtility {
  static format(_this: string, ...args: any[]) {
    return _this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  }

  static normalizeString(string: string) {
    string = string.replace(/ /gm, '').trim().toUpperCase();
    return string;
  }
}
