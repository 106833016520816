import { Tag } from 'antd';
import { countries } from 'countries-list';
import { FC } from 'react';
import { AdminUserAddressResDto } from '../../../api/admin-user/res/admin-user-adress-res.dto';

export const AddressComponent: FC<{ address?: AdminUserAddressResDto | null; label: string }> = (props) => {
  const countryName = () => {
    if (props.address?.country) {
      try {
        const countryKey = props.address.country as any as keyof typeof countries;
        return countries[countryKey].name;
      } catch (error) {
        return props.address.country;
      }
    }
    return '';
  };
  return (
    <address>
      <Tag>{props.label}</Tag>
      {props.address &&
        props.address?.address +
          ' ,' +
          (props.address?.state ?? '') +
          ' ,' +
          (props.address?.postalCode ?? '') +
          ' ,' +
          (props.address?.city ?? '') +
          ' ,' +
          countryName()}
    </address>
  );
};
