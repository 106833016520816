import { createContext, useCallback, useMemo, useState } from 'react';
import { IsErrorResDto } from '../api/error-res.dto';
import { ServerController } from '../api/server.controller';
import { AuthStorageUtility } from '../utilities/auth-storage.utility';

interface AuthState {
  isAuthenticated: boolean;
  user: any | null;
  token: string | null;
}
interface AuthActions {
  checkIfJwtIsValid: () => Promise<void>;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  logout: () => void;
  checkAuthToken: () => void;
  authenticateViaTokenUrl: (url: string) => Promise<void>;
}
const AuthContextPartial: React.Context<Partial<AuthState & AuthActions>> = createContext({});
export const AuthContext = AuthContextPartial as React.Context<AuthState & AuthActions>;

export const AuthProvider = (props: any): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);

  const checkIfJwtIsValid = useCallback(async () => {
    try {
      const response = await ServerController.Auth.authenticate();
      if (response.status === 200) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const decodeJwtToken = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
  };

  const authenticateViaTokenUrl = useCallback(async (url: string) => {
    const response = await ServerController.PublicTokenLogin.get(url);
    if (!IsErrorResDto(response)) {
      AuthStorageUtility.saveToken(response.data.jwt);
      setIsAuthenticated(true);
    } else {
      // handle error response.
    }
  }, []);

  const authMemo = useMemo(
    () => ({
      isAuthenticated,
      token: AuthStorageUtility.getToken(),
      logout: () => {
        AuthStorageUtility.deleteToken();
        setIsAuthenticated(false);
      },
      checkAuthToken: () => {
        const token = AuthStorageUtility.getToken();
        if (token) {
          decodeJwtToken(token);
          return true;
        } else {
          authMemo.logout();
        }
      },
    }),
    [isAuthenticated],
  );

  return (
    <AuthContext.Provider
      value={{
        ...authMemo,
        setIsAuthenticated,
        checkIfJwtIsValid,
        user,
        authenticateViaTokenUrl,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
