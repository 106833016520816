import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { GetPublicPlanResDto } from './res/get-public-plan-res.dto';

export class PublicPlanServer {
  private readonly API_PATH: string = `${API_URL}/public/plan`;

  async get(): Promise<GetPublicPlanResDto | ErrorResDto> {
    try {
      const response = await axios.get<GetPublicPlanResDto>(this.API_PATH);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
