import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostResDto } from '../post-res.dto';
import { PostSuperAdminNumberOfUsersReqDto } from './req/post-super-admin-number-of-users-req.dto';

export class SuperAdminUserNumberOfUsersServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/coach/number-of-users`;

  async post(data: PostSuperAdminNumberOfUsersReqDto): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
