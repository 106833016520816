import axios from 'axios';
import { API_URL } from '../../constants';

export const AuthTokenSymbol = 'authToken';

export class AuthServer {
  private readonly AUTH_API_PATH: string = `${API_URL}/auth`;

  private readonly storeAuthToken = (authToken?: string): void => {
    try {
      if (authToken != null) {
        window.localStorage.setItem(AuthTokenSymbol, authToken);
      } else {
        window.localStorage.removeItem(AuthTokenSymbol);
      }
    } catch {}
  };

  async authenticate() {
    return await axios.get(this.AUTH_API_PATH);
  }

  logout(): void {
    this.storeAuthToken(undefined);
  }
}
