import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Popconfirm, Row, Space, Spin, Table, Typography, Upload, UploadFile, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { PatchSuperAdminExerciseReqDto } from '../../api/super-admin/req/patch-super-admin-exercise-req.dto';
import type { GetSuperAdminExerciseResDto } from '../../api/super-admin/res/get-super-admin-exercise-res.dto';
import { AddExerciseForm } from './components/add-exercise.form';

const { Dragger } = Upload;
export const ExercisePage: FC = (props) => {
  const [state, setState] = useState<GetSuperAdminExerciseResDto[] | undefined>(undefined);
  const load = useCallback(async () => {
    const response = await ServerController.Exercise.getAll();
    if (!IsErrorResDto(response)) {
      setState(response);
    }
  }, []);
  const { t } = useTranslation();

  const [editState, setEditState] = useState<PatchSuperAdminExerciseReqDto>();
  const onEditClicked = (dto: GetSuperAdminExerciseResDto) => {
    setEditState(dto);
    setIsModalOpen(true);
  };
  const onDeleteConfirmed = async (dto: GetSuperAdminExerciseResDto) => {
    await ServerController.Exercise.delete(dto.id);
    await load();
  };

  useEffect(() => {
    load();
  }, [load]);

  const [downloadState, setDownloadState] = useState<Record<string, boolean>>({});
  const columns: ColumnsType<GetSuperAdminExerciseResDto> = [
    {
      title: t(`Module Name`),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Additional actions',
      key: 'additionalActions',
      render: (dto: GetSuperAdminExerciseResDto) => (
        <Row>
          <Space>
            <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '50%' }}
              md={{ flex: '40%' }}
              lg={{ flex: '20%' }}
              xl={{ flex: '10%' }}
              xxl={{ flex: '10%' }}
            >
              <Typography.Link onClick={() => onEditClicked(dto)}>{t`Rename`}</Typography.Link>
            </Col>
            <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '50%' }}
              md={{ flex: '40%' }}
              lg={{ flex: '20%' }}
              xl={{ flex: '10%' }}
              xxl={{ flex: '10%' }}
            >
              <Popconfirm title={t`Sure to delete?`} onConfirm={(_) => onDeleteConfirmed(dto)}>
                <Typography.Link>{t`Delete`}</Typography.Link>
              </Popconfirm>{' '}
            </Col>
            <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '50%' }}
              md={{ flex: '40%' }}
              lg={{ flex: '20%' }}
              xl={{ flex: '10%' }}
              xxl={{ flex: '10%' }}
            >
              <Typography.Link onClick={() => onUploadClicked(dto)}>{t`Upload`}</Typography.Link>
            </Col>
            <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '50%' }}
              md={{ flex: '40%' }}
              lg={{ flex: '20%' }}
              xl={{ flex: '10%' }}
              xxl={{ flex: '10%' }}
            >
                <Typography.Link onClick={() => downloadZip(dto)} download disabled={Boolean(downloadState[dto.id])}>{t`Download`}</Typography.Link>
                <Spin spinning={Boolean(downloadState[dto.id])} style={{position: 'absolute'}}></Spin>
            </Col>
          </Space>
        </Row>
      ),
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isUploadModalOpen, setIsUploadModelOpen] = useState(false);
  const [uploadingDto, setUploadingDto] = useState<GetSuperAdminExerciseResDto>();
  const onUploadClicked = (dto: GetSuperAdminExerciseResDto) => {
    setIsUploadModelOpen(true);
    setUploadingDto(dto);
  };

  const downloadZip = async (dto: GetSuperAdminExerciseResDto) => {
    setDownloadState((x) => {
      return { ...x, [dto.id]: true };
    });
    const response = await ServerController.Exercise.download(dto.id);
    if (IsErrorResDto(response)) {
      message.error(t`Unable to find .zip file on AWS`);
      setDownloadState((x) => {
        return { ...x, [dto.id]: false };
      });
      return;
    }
    const url = window.URL.createObjectURL(new Blob([response]));
    const a = document.createElement('a'); // Create a temporary anchor element
    a.style.display = 'none';
    a.href = url;
    a.download = `${dto.id}_${new Date().toISOString()}.zip`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setDownloadState((x) => {
      return { ...x, [dto.id]: false };
    });
  };

  const [fileList, setFileList] = useState<UploadFile[]>();

  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    if (!fileList) {
      return;
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as any);
    });
    setUploading(true);

    const response = await ServerController.Exercise.upload(uploadingDto!.id, formData);
    if (IsErrorResDto(response)) {
      message.error(t`Upload failed`);
    } else {
      setFileList([]);
      message.success(t`Upload succeeded`);
    }

    setUploading(false);
    setIsUploadModelOpen(false);
  };

  return (
    <>
      <div className="d-flex justify-space-between align-items-center mb-2">
        <div>
          <Button
            type="primary"
            onClick={(x) => {
              setEditState(undefined);
              setIsModalOpen(true);
            }}
          >
            {t`Add New Exercise Module`}
          </Button>
        </div>
      </div>
      <Table bordered size="small" columns={columns} dataSource={state} loading={!state}></Table>
      <Modal
        destroyOnClose={true}
        closable
        open={isModalOpen}
        afterClose={() => {
          setEditState(undefined);
          setIsModalOpen(false);
          load();
        }}
        footer={<></>}
        onCancel={() => {
          setEditState(undefined);
          setIsModalOpen(false);
          load();
        }}
      >
        <AddExerciseForm
          initialValues={editState}
          afterSuccess={(dto) => {
            setIsModalOpen(false);
            load();
          }}
        />
      </Modal>

      <Modal
        open={isUploadModalOpen}
        closable
        onCancel={() => {
          setIsUploadModelOpen(false);
          load();
        }}
        footer={
          <>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={!fileList || fileList?.length === 0}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? t`Uploading` : t`Save on AWS`}
            </Button>
          </>
        }
      >
        <Dragger
          listType="picture"
          className="upload-list-inline"
          multiple
          showUploadList={{
            removeIcon: () => {
              return (
                <span style={{ color: 'red !important' }}>
                  <DeleteOutlined color="red" />
                </span>
              );
            },
          }}
          onRemove={(file) => {
            if (!fileList) {
              return;
            }
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
          beforeUpload={(file, allFiles) => {
            if (allFiles) {
              setFileList([...allFiles]);
            } else {
              setFileList([file]);
            }
            return false;
          }}
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">{t`Click or drag file to this area to upload`}</p>
        </Dragger>
      </Modal>
    </>
  );
};
