import { AthletesPage } from './athlete.page';
import { AthletePageOutlet } from './athlete.page-outlet';

export const AthleteRoutes = {
  path: '/athletes',
  element: <AthletePageOutlet />,
  subroutes: [
    {
      path: '',
      element: <AthletesPage />,
    },
  ],
};
