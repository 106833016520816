import { Button } from 'antd';
import { IsErrorResDto } from '../../../api/error-res.dto';
import { ServerController } from '../../../api/server.controller';
import { COACH_APP_URL } from '../../../constants';
import { StringUtility } from '../../../utilities/string.utility';

type Props = { userId: string };

export default function ImpersonateUserComponent(props: Props) {
  const onClick = async () => {
    const response = await ServerController.UserImpersonate.post({ ...props });
    if (!IsErrorResDto(response)) {
      window.open(StringUtility.format(COACH_APP_URL, response.data.token, response.data.email));
    }
  };
  return (
    <Button onClick={onClick} type="dashed" target="_blank">
      Impersonate user
    </Button>
  );
}
