import { ExercisePage } from './exercise.page';
import { ExercisePageOutlet } from './exercise.page-outlet';

export const ExerciseRoutes = {
  path: '/exercise',
  element: <ExercisePageOutlet />,
  subroutes: [
    {
      path: '',
      element: <ExercisePage />,
    },
  ],
};
