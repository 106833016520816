import { Card } from 'antd';
import { FC, useCallback, useEffect } from 'react';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { AndroidFormComponent } from './components/android-form.component';

export const AndroidPage: FC = (props) => {
  const load = useCallback(async () => {
    const response = await ServerController.Android.get();
    if (!IsErrorResDto(response)) {
      console.log(response.data);
    }
  }, []);
  useEffect(() => {
    load();
  }, [load]);
  return (
    <Card>
      <AndroidFormComponent />
    </Card>
  );
};
