import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { AppAuthGuard } from './pages/guards/app-auth.guard';

export const App = function App() {
  return (
    <Router>
      <AppAuthGuard></AppAuthGuard>
    </Router>
  );
};
