import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostResDto } from '../post-res.dto';
import { PostSuperAdminActivateTrialReqDto } from './req/post-super-admin-activate-trial-req.dto';

export class SuperAdminUserDeActivateTrialServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/coach/deactivate-trial`;

  async post(data: PostSuperAdminActivateTrialReqDto): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
