import axios from 'axios';
import { API_URL } from '../../constants';
import { HandleAxiosErrorResDto } from '../error-res.dto';

export class CoachSubscriptionServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/coach/subscription/extend`;

  async patch(userId:string) {
    try {
      const response = await axios.patch<any>(`${this.API_PATH}/${userId}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
