import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostResDto } from '../post-res.dto';
import { PostSuperAdminAndroidReqDto } from './req/post-super-admin-android-req.dto';
import { GetSuperAdminAndroidResDto } from './res/get-super-admin-android-res.dto';

export class SuperAdminAndroidServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/android`;

  async post(data: PostSuperAdminAndroidReqDto): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async get(): Promise<GetSuperAdminAndroidResDto | ErrorResDto> {
    try {
      const response = await axios.get<GetSuperAdminAndroidResDto>(`${this.API_PATH}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
