import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';

import type { PatchSuperAdminDataRecordingReqDto } from './req/patch-super-admin-data-recording-req.dto';

export class SuperAdminCoachDataRecordingUserServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/data-recording`;

  async patch(dto: PatchSuperAdminDataRecordingReqDto, userId: string): Promise<boolean | ErrorResDto> {
    try {
      const response = await axios.patch<boolean>(`${this.API_PATH}/${userId}`, dto);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

}
