import { AndroidPage } from './android.page';
import { AndroidPageOutlet } from './android.page-outlet';

export const AndroidRoutes = {
  path: '/android',
  element: <AndroidPageOutlet />,
  subroutes: [
    {
      path: '',
      element: <AndroidPage />,
    },
  ],
};
