import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';

import type { PostSuperAdminExerciseUserReqDto } from './req/post-super-admin-exercise-user-req.dto';
import type { GetSuperAdminExerciseUserResDto } from './res/get-super-admin-exercise-user-res.dto';

export class SuperAdminExerciseUserServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/exercise/user`;

  async post(dto: PostSuperAdminExerciseUserReqDto, userId: string): Promise<boolean | ErrorResDto> {
    try {
      const response = await axios.post<boolean>(`${this.API_PATH}/${userId}`, dto);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async get(id:string) {
    try {
      const response = await axios.get<GetSuperAdminExerciseUserResDto[]>(`${this.API_PATH}/${id}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
