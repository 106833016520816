import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';

import { PatchSuperAdminExerciseReqDto } from './req/patch-super-admin-exercise-req.dto';
import type { PostSuperAdminExerciseReqDto } from './req/post-super-admin-exercise-req.dto';
import type { GetSuperAdminExerciseResDto } from './res/get-super-admin-exercise-res.dto';

export class SuperAdminExerciseServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/exercise`;

  async post(data: PostSuperAdminExerciseReqDto): Promise<GetSuperAdminExerciseResDto | ErrorResDto> {
    try {
      const response = await axios.post<GetSuperAdminExerciseResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async getAll(): Promise<GetSuperAdminExerciseResDto[] | ErrorResDto> {
    try {
      const response = await axios.get<GetSuperAdminExerciseResDto[]>(`${this.API_PATH}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async patch(dto: PatchSuperAdminExerciseReqDto){
    try {
      const response = await axios.patch(`${this.API_PATH}/${dto.id}`, dto);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async delete(id:string) {
    try {
      const response = await axios.delete(`${this.API_PATH}/${id}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async upload(id: string, formData: FormData) {
    try {
      const response = await axios.post<GetSuperAdminExerciseResDto>(`${this.API_PATH}/upload/${id}`, formData);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async download(id: string) {
    try {
      const response = await axios.get(`${this.API_PATH}/${id}`, {responseType: 'blob'});
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
