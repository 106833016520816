import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import type { PostSuperAdminUserImpersonateResDto } from './res/post-super-admin-post-impersonate-res.dto';

export class SuperAdminUserImpersonateServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/user/impersonate`;

  async post(data: { userId: string }): Promise<PostSuperAdminUserImpersonateResDto | ErrorResDto> {
    try {
      const response = await axios.post<PostSuperAdminUserImpersonateResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
