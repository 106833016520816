import { Button, Form, Input, message, Space } from 'antd';
import { useState } from 'react';

import { ServerController } from '../../../api/server.controller';

import { useTranslation } from 'react-i18next';
import { IsErrorResDto } from '../../../api/error-res.dto';
import { PatchSuperAdminExerciseReqDto } from '../../../api/super-admin/req/patch-super-admin-exercise-req.dto';
import type { PostSuperAdminExerciseReqDto } from '../../../api/super-admin/req/post-super-admin-exercise-req.dto';
import { GetSuperAdminExerciseResDto } from '../../../api/super-admin/res/get-super-admin-exercise-res.dto';

type Props = {
  afterSuccess?: (dto: GetSuperAdminExerciseResDto) => Promise<void> | void;
  initialValues?: PatchSuperAdminExerciseReqDto
};

export const AddExerciseForm = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm<PostSuperAdminExerciseReqDto>();

  const onFinish = async (values: PostSuperAdminExerciseReqDto) => {
    setLoading(true);
    let data: any
    if(props.initialValues){
      data = await ServerController.Exercise.patch({id: props.initialValues.id, name: values.name})
    }
    else {
      data = await ServerController.Exercise.post(values);
    }
    if (!IsErrorResDto(data)) {
      message.success(props.initialValues ? t`Module name updated` : t`New module added`);
      props?.afterSuccess?.(data);
    }
    setLoading(false);
  };

  const { t } = useTranslation();

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off" initialValues={props?.initialValues}>
      <Form.Item
        name="name"
        label={t('Name')}
        rules={[{ required: true, message: 'You must enter a valid exercise module name' }]}
      >
        <Input placeholder={t`Exercise module name`} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            {t`Save`}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
