import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { SideBarLinks } from './sidebar.links';

export const SideBarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname.split('/')[1];

  return (
    <Menu
      theme="dark"
      className="py-3"
      mode="inline"
      defaultSelectedKeys={['dashboard']}
      selectedKeys={[location]}
      onClick={(item) => navigate(item.key)}
      items={SideBarLinks()}
    />
  );
};
