import { Button, Form, Input, message, Space } from 'antd';
import { useState } from 'react';
import { ServerController } from '../../../api/server.controller';
import { PostSuperAdminAndroidReqDto } from '../../../api/super-admin/req/post-super-admin-android-req.dto';

type Props = {};

export const AndroidFormComponent = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm<PostSuperAdminAndroidReqDto>();

  const onFinish = async (values: PostSuperAdminAndroidReqDto) => {
    try {
      setLoading(true);
      await ServerController.Android.post(values);
      message.success('Updated successfully');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
      <Form.Item
        name="buildUrl"
        label="URL"
        rules={[
          { required: true, message: 'You must enter a valid android url' },
          { type: 'url', message: 'The url is not valid.' },
        ]}
      >
        <Input placeholder="Enter android url" />
      </Form.Item>
      <Form.Item
        name="buildPassCode"
        label="Pass code"
        rules={[
          { required: true, message: 'Pass code is required' },
          { type: 'string', min: 4, message: 'Pass code must be at least 4 characters' },
        ]}
      >
        <Input.Password placeholder="Enter android pass code" />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
